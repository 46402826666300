import {on} from 'delegated-events'

on('click', '.js-toc-retry', function (event) {
  if (!(event.target instanceof Element)) return
  const menu = event.target.closest<HTMLElement>('.select-menu')!
  const loader = menu.querySelector<HTMLElement>('include-fragment')!
  const src = loader.getAttribute('src')!
  loader.setAttribute('src', '')
  loader.setAttribute('src', src)
})
