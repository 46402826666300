import {observe} from '@github/selector-observer'

document.addEventListener('head-ref-deleted', () => {
  const restoreEvents = document.querySelectorAll('.pull-request-ref-restore')
  if (restoreEvents.length > 0) {
    for (const restoreBranchTimelineItem of restoreEvents) {
      restoreBranchTimelineItem.classList.remove('last')
    }
  }
})

// Pull Request: Restore branch event link

// Add ".last" class to the last "Restore" button on the page.
//
// See .pull-request-ref-restore in pull_request.scss
function syncLast() {
  const refRestores = document.querySelectorAll('.pull-request-ref-restore')
  for (let i = 0; i < refRestores.length; i++) {
    refRestores[i]!.classList.toggle('last', i === refRestores.length - 1)
  }
}

observe('.pull-request-ref-restore', {
  add: syncLast,
  remove: syncLast,
})

// Some hacks to help Restore Branch visibility.
//
// Hoists the "restorable" state from the the merging partial onto the timeline
// container for styling.
//
// Also see #js-pull-restorable on pull_requests/_merging.html.erb.
//
// PDI: It'd be nice if we could live update this state directly on the container
// rather than having to sync it around the DOM.
function syncRestorable() {
  const restorable = document.querySelector('#js-pull-restorable') != null
  const timeline = document.querySelector('.js-pull-discussion-timeline')
  if (timeline) {
    timeline.classList.toggle('is-pull-restorable', restorable)
  }
}

observe('#js-pull-restorable', {
  add: syncRestorable,
  remove: syncRestorable,
})
