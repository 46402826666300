import hashChange from '../behaviors/hash-change'
import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'

import visible from '@github-ui/visible'

on('click', '.js-compare-tab', function (event) {
  for (const tab of document.querySelectorAll('.js-compare-tab.selected')) {
    tab.classList.remove('selected')
  }
  event.currentTarget.classList.add('selected')

  for (const content of document.querySelectorAll('#commits_bucket, #files_bucket, #commit_comments_bucket')) {
    /* eslint-disable-next-line github/no-d-none */
    content.classList.add('d-none')
  }

  const selectedTab = event.currentTarget as HTMLAnchorElement
  const id = selectedTab.hash

  /* eslint-disable-next-line github/no-d-none */
  document.querySelector<HTMLElement>(id)!.classList.remove('d-none')
  event.preventDefault()
})

// When an anchor references an element under the "Files Changed" tab
// but is not visible, switch to the tab.
//
//   /compare/foo-branch#diff-0
//   /compare/foo-branch#L1R1
hashChange(function ({target}) {
  if (!(target instanceof HTMLElement)) return

  const bucket = target.closest('#commits_bucket, #files_bucket, #commit_comments_bucket')

  if (bucket && bucket instanceof HTMLElement && !visible(bucket)) {
    document.querySelector<HTMLElement>(`.js-compare-tab[href="#${bucket.id}"]`)!.click()
  }
})

// Show cross repo chooser for "compare across forks" link.
on('click', '.js-toggle-range-editor-cross-repo', function () {
  document.querySelector<HTMLElement>('.js-range-editor')!.classList.toggle('is-cross-repo')
})

observe('.js-compare-pr.open', {
  add() {
    document.body.classList.add('is-pr-composer-expanded')
  },
  remove() {
    document.body.classList.remove('is-pr-composer-expanded')
  },
})
