import {on} from 'delegated-events'
import {scrollToFragmentTarget} from '../sticky-scroll-into-view'

// Reload the conversations menu each time it is clicked (excluding the first click)
on(
  'click',
  '.js-conversation-menu-button',
  (function () {
    let firstClick = true
    return async function (event: MouseEvent & {currentTarget: Element}) {
      if (firstClick === false) {
        const button = event.currentTarget
        const conversationsMenu = button.closest<HTMLElement>('.select-menu')!
        const detailsMenu = conversationsMenu.querySelector<HTMLElement>('details-menu')
        if (!detailsMenu) return
        detailsMenu.textContent = ''

        const url = `${detailsMenu.getAttribute('src')!}?instrument=1`
        const response = await fetch(url)
        if (response.ok) {
          detailsMenu.innerHTML = await response.text()
        }
      }
      firstClick = false
    }
  })(),
)

// Close the conversations menu when a conversation is clicked
// and ensure that the conversation is scrolled to
on('click', '.js-conversations-menu-item', function (event) {
  const anchor = event.currentTarget as HTMLAnchorElement
  const details = anchor.closest('.js-conversations-details')!
  if (details.hasAttribute('open')) {
    details.removeAttribute('open')
  }
  window.location.hash = anchor.hash
  scrollToFragmentTarget(document)
})

// Flip the caret and toggle border each time resolved conversations section is clicked
on('click', '.js-resolved-conversations', async function (event) {
  const caret = event.currentTarget.querySelector<HTMLElement>('.dropdown-caret')!
  const selectMenuHeader = event.currentTarget.querySelector<HTMLElement>('.select-menu-header')!
  flipCaret(caret)
  selectMenuHeader.classList.toggle('border-bottom-0')
})

function flipCaret(caret: HTMLElement) {
  if (caret.style.transform === '') {
    caret.style.transform = 'rotate(180deg)'
  } else {
    caret.style.transform = ''
  }
}
