import {on} from 'delegated-events'
import {remoteForm} from '@github/remote-form'

on('change', '.js-collab-checkbox', function ({currentTarget}) {
  const form = (currentTarget as HTMLInputElement).form!

  // Clear option group error states.
  for (const el of form.querySelectorAll('.errored')) {
    el.classList.remove('errored')
  }

  // Show loading indicator.
  const option = currentTarget.closest<HTMLElement>('.js-collab-option')!
  const indicator = option.querySelector<HTMLElement>('.js-status-indicator')!
  indicator.classList.remove('status-indicator-success', 'status-indicator-failed')
  indicator.classList.add('status-indicator-loading')
})

remoteForm('.js-collab-form', async function (form, wants) {
  try {
    await wants.text()
  } catch {
    for (const el of form.querySelectorAll('.status-indicator-loading')) {
      el.classList.remove('status-indicator-loading')
      el.classList.add('status-indicator-failed')

      // Show error message.
      const option = el.closest<HTMLElement>('.js-collab-option')!
      option.classList.add('errored')

      const checkbox = option.querySelector<HTMLInputElement>('.js-collab-checkbox')!
      checkbox.checked = !checkbox.checked
    }

    // Clear all success indicators.
    for (const el of form.querySelectorAll('.status-indicator-success')) {
      el.classList.remove('status-indicator-success')
    }

    return
  }

  // Clear option group error states.
  for (const el of form.querySelectorAll('.errored')) {
    el.classList.remove('errored')
  }

  // Show success indicator.
  for (const el of form.querySelectorAll('.status-indicator-loading')) {
    el.classList.remove('status-indicator-loading')
    el.classList.add('status-indicator-success')
  }
})
