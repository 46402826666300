import {controller, target, targets} from '@github/catalyst'
import type {FileFilterElement} from '../../../../components/pull_requests/file_tree/file-filter-element'
import {debounce} from '@github/mini-throttle/decorators'

interface FilterState {
  hideDeletedFiles: boolean
  hideViewedFiles: boolean
  showManifestFilesOnly: boolean
  selectedOwners: string[]
  showCodeownersFiles: boolean
  selectedFileTypes: string[]
  fileTypeFilterActive: boolean
  filtersActive: boolean
}

@controller
export class DiffFileFilterElement extends HTMLElement {
  @target declare blankslate: HTMLElement
  @target declare resetFilters: HTMLElement | undefined

  @targets declare diffEntries: HTMLElement[]
  @targets declare progressiveLoaders: HTMLElement[]

  @targets declare treeEntries: HTMLElement[]
  @target declare treeRoot: HTMLElement | undefined
  @target declare fileFilter: FileFilterElement

  declare filterState: FilterState

  connectedCallback() {
    this.filterState = {
      hideDeletedFiles: false,
      hideViewedFiles: false,
      showManifestFilesOnly: false,
      selectedOwners: [],
      showCodeownersFiles: false,
      selectedFileTypes: [],
      fileTypeFilterActive: false,
      filtersActive: false,
    }
  }

  applyFilter(event: CustomEvent) {
    this.filterState = event.detail
    this.showResetFilters()
    this.filterFiles()
    this.filterTreeFiles()
    this.hideEmptyDirectories()
    this.showBlankslateIfEmpty()
  }

  clearFilters(event: Event): void {
    event.preventDefault()
    this.fileFilter.clearFilters()
  }

  private filterFiles(): void {
    for (const file of this.diffEntries) {
      const fileDetails = file.closest<HTMLElement>('.js-file.js-details-container')!
      fileDetails.hidden = !this.shouldShowFile(file)
    }
  }

  private filterTreeFiles(): void {
    for (const treeFile of this.treeEntries) {
      const showTreeFile = this.shouldShowFile(treeFile)
      treeFile.hidden = !showTreeFile
      treeFile.toggleAttribute('data-skip-substring-filter', !showTreeFile)
    }
  }

  @debounce(20)
  hideEmptyDirectories(): void {
    if (this.treeRoot) {
      this.hideEmptyDirectory(this.treeRoot)
    }
  }

  private hideEmptyDirectory(node: HTMLElement): number {
    // Recursively traverses the nodes of the tree to hide directories with
    // no visible children.
    const isRoot = node.getAttribute('data-tree-entry-type') === 'root'
    const isDirectory = node.getAttribute('data-tree-entry-type') === 'directory'

    if (isRoot || isDirectory) {
      const directChildren = node.querySelectorAll(isRoot ? ':scope > .js-tree-node' : ':scope > ul > .js-tree-node')

      let visibleChildCount = 0

      for (const child of directChildren) {
        visibleChildCount = visibleChildCount + this.hideEmptyDirectory(child as HTMLElement)
      }

      if (visibleChildCount === 0) {
        node.hidden = true
        return 0
      }
      node.hidden = false
      return 1
    }

    if (!node.hidden) {
      return 1
    }
    return 0
  }

  async refilterAfterAsyncLoad() {
    // Diffs are batched and loaded progressively. Those that finish loading after
    // a filter has been applied don't get filtered. This function is
    // intended to hook into the `include-fragment-replace` event emitted by
    // the <include-fragment>, so that we ensure filters are applied.
    // We use a promise to "wait" a tick to ensure the include-fragment has actually been replaced.
    await Promise.resolve()
    this.refilterLoadedFiles()
    this.showBlankslateIfEmpty()
  }

  private refilterLoadedFiles(): void {
    if (this.filterState.filtersActive) {
      this.filterFiles()
    }
  }

  private shouldShowFile(file: HTMLElement): boolean {
    let show = true

    // FILE EXTENSION FILTER
    const fileExtension = file.getAttribute('data-file-type')
    if (fileExtension) {
      show = this.filterState.selectedFileTypes.includes(fileExtension)
    }

    // VIEWED FILE FILTER
    if (this.filterState.hideViewedFiles && show) {
      const viewedFile = file.hasAttribute('data-file-user-viewed')
      if (viewedFile) {
        show = false
      }
    }

    // CODEOWNERS FILE FILTER
    if (this.filterState.showCodeownersFiles && show) {
      const fileOwners = (file.getAttribute('data-codeowners') || '').split(',')
      show = fileOwners.filter(owner => this.filterState.selectedOwners.includes(owner)).length > 0
    }

    // DELETED FILE FILTER
    if (this.filterState.hideDeletedFiles && show) {
      const deletedFile = file.getAttribute('data-file-deleted') === 'true'
      if (deletedFile) {
        show = false
      }
    }

    // MANIFEST FILE FILTER
    if (this.filterState.showManifestFilesOnly && show) {
      const manifestFile = file.hasAttribute('data-file-manifest')
      if (!manifestFile) {
        show = false
      }
    }
    return show
  }

  private showResetFilters(): void {
    if (this.resetFilters) {
      this.resetFilters.hidden = !this.filterState.filtersActive
    }
  }

  private showBlankslateIfEmpty(): void {
    if (!this.blankslate) {
      return
    }

    if (this.progressiveLoaders.length > 0) {
      this.blankslate.hidden = true
      return
    }

    if (this.diffEntries.length === 0) {
      this.blankslate.hidden = true
      return
    }

    const isNotEmpty = Array.from(this.diffEntries).some(file => !file.hidden)
    this.blankslate.hidden = isNotEmpty
  }
}
