import {fromEvent} from '@github-ui/subscription'
import hashChange from '../behaviors/hash-change'
import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'
import {scrollIntoView} from '../sticky-scroll-into-view'
import {updateURLState} from './file-filter-persistence'

on('details:toggled-group', '.js-file.js-details-container', function (event) {
  if (event.target !== event.currentTarget) return
  toggledAllFileContents(event.detail.open)
})

// Scroll file header into view if top of file is offscreen (sticky file header).
on('details:toggled', '.js-file.js-details-container', function (event) {
  if (event.target !== event.currentTarget) return
  const container = event.currentTarget
  if (container.getBoundingClientRect().top < 0) {
    scrollIntoView(container)
  }
})

hashChange(() => {
  const hash = window.location.hash.slice(1)
  if (!hash) return
  const target = document.getElementsByName(hash)[0]
  if (!target) return
  const container = target.nextElementSibling
  if (container && container.matches('.js-file.js-details-container')) {
    container.classList.add('open')
    container.classList.add('Details--on')
  }
})

observe('.js-file-header-dropdown', {
  subscribe: el => fromEvent(el, 'toggle', toggleFileHeaderMenu),
})

// Adjusts the z-index of a file header with an open dropdown menu to ensure
// it's always visible atop the other files. Otherwise, the open menu of a
// collapsed file is displayed beneath the next file.
function toggleFileHeaderMenu({currentTarget}: Event) {
  const details = currentTarget as Element
  const isOpen = details.hasAttribute('open')
  const file = details.closest<HTMLElement>('.js-file-header')!
  file.classList.toggle('has-open-dropdown', isOpen)
}

function toggledAllFileContents(open: boolean) {
  const fileFilter = document.querySelector('.js-file-filter')
  if (!fileFilter) {
    return
  }

  // Toggle all file type inputs with given checked value
  const fileTypeOptions = getFileTypeOptions()
  for (const checkbox of fileTypeOptions) {
    checkbox.checked = open
  }

  const deletedFilesInput = getDeletedFilesInput()
  if (deletedFilesInput) {
    deletedFilesInput.checked = !open
  }

  const isActive = fileFilterActive()

  toggleHighlightActiveFilter(isActive)
  toggleResetFileFiltersButton(isActive)
  toggleAvailableOptions()
  toggleSelectAll()
  toggleFileTypeCounts()
  updateURLState(
    isActive,
    whitespaceFilterActive(),
    getSelectedFileTypes(),
    codeownerFilterActive(),
    getSelectedOwners(),
    manifestFilterActive(),
    deletedFilterActive(),
    viewedFilterActive(),
  )
}

// Get file type names that have be selected through the checkboxes
function getSelectedFileTypes(): string[] {
  const checkedFileOptions = Array.from(getFileTypeOptions()).filter(option => option.checked)
  return checkedFileOptions.map(option => option.value)
}

// Get checkbox elements for the file types in the diff
function getFileTypeOptions() {
  return document.querySelectorAll<HTMLInputElement>('.js-diff-file-type-option')
}

// Get deleted files checkbox
function getDeletedFilesInput(): HTMLInputElement | undefined {
  const input = document.querySelector('.js-deleted-files-toggle')
  if (input instanceof HTMLInputElement) {
    return input
  }
}

// Are any files being filtered?
function fileFilterActive(): boolean {
  return (
    fileTypeFilterActive() ||
    deletedFilterActive() ||
    viewedFilterActive() ||
    codeownerFilterActive() ||
    manifestFilterActive()
  )
}

function codeownerFilterActive(): boolean {
  return getSelectedOwners().length > 0
}

function getSelectedOwners(): string[] {
  const ownerOptions = document.querySelectorAll<HTMLInputElement>('.js-diff-owner-option')
  const checkedFileOptions = Array.from(ownerOptions).filter(option => option.checked)
  return checkedFileOptions.map(option => option.value)
}

// Are any files being filtered by type?
function fileTypeFilterActive(): boolean {
  return getSelectedFileTypes().length !== getFileTypeOptions().length
}

// Are any files being filtered by manifest status?
function manifestFilterActive(): boolean {
  const showManifestFiles = document.querySelector<HTMLInputElement>('.js-manifests-option')
  if (showManifestFiles) {
    return showManifestFiles.checked
  } else {
    return false
  }
}

// Are any files being filtered by deleted status?
function deletedFilterActive(): boolean {
  const hideDeletedFiles = getDeletedFilesInput()
  if (hideDeletedFiles) {
    return !hideDeletedFiles.checked
  } else {
    return false
  }
}

// Are any files being filtered by viewed status?
function viewedFilterActive(): boolean {
  const input = document.querySelector<HTMLInputElement>('.js-viewed-files-toggle')
  if (input) {
    return !input.checked
  } else {
    return false
  }
}

// Has the whitespace "w" url parameter been passed
function whitespaceFilterActive(): boolean {
  const params = new URLSearchParams(window.location.search)
  return params.has('w')
}

// Changes the file filter drop down button to show if the filter is active
function toggleHighlightActiveFilter(highlight: boolean) {
  const fileFilterButton = document.querySelector<HTMLElement>('.js-file-filter-text')!
  fileFilterButton.classList.toggle('color-fg-accent', highlight)
}

// Shows or hides the 'Reset' CTA
function toggleResetFileFiltersButton(show: boolean) {
  const resetFileFilter = document.querySelector('.js-reset-filters')
  const commitsCurrentlyFiltered = document.querySelector('.js-commits-filtered')

  if (resetFileFilter != null && !commitsCurrentlyFiltered) {
    ;(resetFileFilter as HTMLElement).hidden = !show
  }
}

// Toggles text for select all file types cta
function toggleSelectAll() {
  const typefilterActive = fileTypeFilterActive()
  const selectAllContainer = document.querySelector<HTMLElement>('.js-file-filter-select-all-container')!
  const selectedMarkup = typefilterActive ? 'data-select-all-markup' : 'data-all-selected-markup'
  const newMarkup = selectAllContainer.getAttribute(selectedMarkup)!
  selectAllContainer.textContent = newMarkup
  selectAllContainer.classList.toggle('color-fg-muted', !typefilterActive)
  selectAllContainer.classList.toggle('color-fg-accent', typefilterActive)
}

// Toggles which filters are disabled given other filters
function toggleAvailableOptions() {
  // Disables file types that consist of only deleted files when deleted files filter is active
  const fileTypeOptions = getFileTypeOptions()
  const deletedFilesHidden = deletedFilterActive()
  for (const checkbox of fileTypeOptions) {
    if (deletedFilesHidden) {
      const allFilesDeleted = checkbox.getAttribute('data-non-deleted-files-count') === '0'
      checkbox.disabled = allFilesDeleted
    } else {
      checkbox.disabled = false
    }
  }
}

function toggleFileTypeCounts() {
  const fileTypeCounts = document.querySelectorAll('.js-file-type-count')
  for (const count of fileTypeCounts) {
    const countMarkup = deletedFilterActive() ? 'data-non-deleted-file-count-markup' : 'data-all-file-count-markup'

    const newMarkup = count.getAttribute(countMarkup)
    if (newMarkup) {
      count.textContent = newMarkup
    }
  }
}
