import {on} from 'delegated-events'
import {preservePosition} from 'scroll-anchoring'

// TODO: Refactor to use data-hotkey
/* eslint eslint-comments/no-use: off */
/* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
// We can't rely on `event.altKey` of the click event since in Firefox it
// doesn't accurately reflect whether Alt was pressed when Space or Enter were
// used to toggle the details element instead of a mouse.
let altPressed = false
document.addEventListener('keydown', function (event: KeyboardEvent) {
  if ('Alt' === event.key) altPressed = true
})
document.addEventListener('keyup', function (event: KeyboardEvent) {
  if ('Alt' === event.key) altPressed = false
})

on('click', '.js-toggle-outdated-comments', function (event) {
  if (!((event instanceof MouseEvent && event.altKey) || altPressed) || !(event.currentTarget instanceof HTMLElement)) {
    return
  }

  const current = event.currentTarget
  const container = current.closest<HTMLElement>('details')!

  function toggleAll() {
    const open = container.hasAttribute('open')
    for (const other of document.querySelectorAll('.js-toggle-outdated-comments')) {
      if (other !== current) {
        const otherContainer = other.closest<HTMLElement>('details')!
        otherContainer.toggleAttribute('open', open)
      }
    }
  }

  setTimeout(() => {
    preservePosition(current, toggleAll)
  })
})

/* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */
