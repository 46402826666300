import {observe} from '@github/selector-observer'

// Everything here is terrible
function syncBodyClass() {
  const meta = document.querySelector('meta[name=diff-view]')
  const diffView = meta && meta instanceof HTMLMetaElement ? meta.content : ''
  const diffSplit = document.querySelector('.js-file-diff-split')

  const enabled =
    (diffView === 'split' && diffSplit) || document.querySelector('.wants-full-width-container') ? true : false
  document.body.classList.toggle('full-width', enabled)
}

// resync body class
observe('meta[name=diff-view]', {
  add: syncBodyClass,
  remove: syncBodyClass,
})
observe('.js-file-diff-split', {
  add: syncBodyClass,
  remove: syncBodyClass,
})
observe('.js-compare-tab.selected', {
  add: syncBodyClass,
  remove: syncBodyClass,
})
observe('.wants-full-width-container', {
  add: syncBodyClass,
  remove: syncBodyClass,
})
