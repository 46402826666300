import {remoteForm} from '@github/remote-form'
import {TemplateInstance} from '@github/template-parts'
import {scrollIntoView} from '../sticky-scroll-into-view'
import {toggleDetailsTarget} from '../behaviors/details'

remoteForm('.js-toggle-user-reviewed-file-form', async function (form, send) {
  const container = form.closest<HTMLElement>('.js-details-container')!
  const reviewedFile = !!container.querySelector('.js-reviewed-file')
  const fileLevelComments = container.querySelector<HTMLElement>('.js-file-level-comments-table')
  const open = container.classList.contains('open')
  const header = container.querySelector('.js-file-header')
  const topFile = header ? header.getBoundingClientRect().top === 60 : false

  if ((!reviewedFile && open) || (reviewedFile && !open)) {
    toggleDetailsTarget(container)
    if (fileLevelComments) fileLevelComments.classList.toggle('hidden')
    if (topFile) {
      scrollIntoView(container)
    }
  }
  // perceived performance for the state change
  const button = form.querySelector<HTMLElement>('.js-reviewed-toggle')!
  if (reviewedFile) {
    button.classList.remove('color-bg-accent', 'color-border-accent')
    button.classList.add('color-fg-muted', 'color-border-default')
  } else {
    button.classList.remove('color-fg-muted', 'color-border-default')
    button.classList.add('color-bg-accent', 'color-border-accent')
  }

  // make sure progress bar doesn't get out of sync
  const checkbox = button.querySelector<HTMLInputElement>('.js-reviewed-checkbox')!
  checkbox.disabled = true

  const bar = document.querySelector('progress-bar')
  if (bar instanceof ProgressBarElement) {
    if (reviewedFile) {
      bar.decrement()
    } else {
      bar.increment()
    }
  }

  // keep file tree in sync
  const fileTreeItem = document.getElementById(`file-tree-item-${container.id}`)
  if (fileTreeItem) {
    fileTreeItem.toggleAttribute('data-file-user-viewed')
  }

  try {
    const response = await send.html()
    const target = form.closest('.js-replace-file-header-review')
    if (target) {
      target.replaceWith(response.html)
      container.toggleAttribute('data-file-user-viewed')
    }
  } catch {
    // eslint-disable-next-line i18n-text/no-en
    const message = 'There was an error marking the file as viewed.'
    const template = document.querySelector<HTMLTemplateElement>('template.js-flash-template')!
    template.after(new TemplateInstance(template, {className: 'flash-error', message}))
    checkbox.checked = false
    checkbox.disabled = false
  }
})

function parseRatio(bar: ProgressBarElement): string[] {
  return bar.getAttribute('ratio')!.split('/')
}

function refresh(bar: ProgressBarElement) {
  if (bar) {
    const [parts, whole] = parseRatio(bar)
    const percent = parseInt(parts!) / parseInt(whole!)
    const div = bar.querySelector('.js-review-progress')
    const span = bar.querySelector('.js-review-count')

    if (div instanceof HTMLElement && span) {
      div.style.width = `${percent * 100}%`
      span.textContent = `${parts} / ${whole}`
    }
  }
}

class ProgressBarElement extends HTMLElement {
  static get observedAttributes() {
    return ['ratio']
  }

  attributeChangedCallback(attrName: string) {
    if (attrName === 'ratio') refresh(this)
  }

  connectedCallback() {
    refresh(this)
  }

  increment() {
    const [parts, whole] = parseRatio(this)
    const newParts = Math.min(parseInt(parts!) + 1, parseInt(whole!))
    this.setAttribute('ratio', `${newParts}/${whole}`)
  }

  decrement() {
    const [parts, whole] = parseRatio(this)
    let newParts = Math.min(parseInt(parts!) - 1, parseInt(whole!))
    newParts = Math.max(newParts, 0)
    this.setAttribute('ratio', `${newParts}/${whole}`)
  }
}

if (!window.customElements.get('progress-bar')) {
  window.ProgressBarElement = ProgressBarElement
  window.customElements.define('progress-bar', ProgressBarElement)
}

declare global {
  interface Window {
    ProgressBarElement: typeof ProgressBarElement
  }
}
