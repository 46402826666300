import {on} from 'delegated-events'
import {reload} from '@github-ui/updatable-content'
import {remoteForm} from '@github/remote-form'

on('click', '.js-code-scanning-apply-autofix-form .js-code-scanning-apply-autofix-submit', function (event) {
  const submitButton = event.currentTarget
  const form = submitButton.closest<HTMLFormElement>('.js-code-scanning-apply-autofix-form')!

  const commitTitleInput = form.querySelector<HTMLInputElement>('input[name=commit_title]')!
  const commitMessageInput = form.querySelector<HTMLTextAreaElement>('textarea[name=commit_message]')!
  let message = commitTitleInput.value.trim()
  if (message === '') {
    message = commitTitleInput.defaultValue
  }
  const commitMessage = commitMessageInput.value.trim()
  if (commitMessage !== '') {
    message = `${message}\n\n${commitMessage}\n`
  }
  commitTitleInput.disabled = true
  commitMessageInput.disabled = true

  const messageInput = document.createElement('input')
  messageInput.setAttribute('type', 'hidden')
  messageInput.setAttribute('name', 'message')
  messageInput.value = message
  form.appendChild(messageInput)
})

remoteForm('.js-code-scanning-apply-autofix-form', async function (form, wants) {
  const dropdown = form.closest<HTMLElement>('details')!
  const containerParent = form.closest<HTMLElement>('.js-code-scanning-apply-autofix-container')!
  const errorMessageContainer = containerParent.querySelector<HTMLElement>('.js-error-message-placeholder')!

  try {
    await wants.json()

    dropdown.remove()
    reload()
  } catch (error) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    if (error.name !== 'QueryError') {
      // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
      const errorMessage = error.response.json && error.response.json.error
      const errorMessageElement = errorMessageContainer.querySelector<HTMLElement>('.js-error-message')!

      errorMessageContainer.hidden = false
      errorMessageElement.textContent = errorMessage

      dropdown.remove()
    }
  }
})
