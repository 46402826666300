import {on} from 'delegated-events'

// Shows blankslate if files empty
function checkEmptyFilterResults() {
  const blankSlate = document.querySelector<HTMLInputElement>('.js-file-filter-blankslate')
  if (!blankSlate) {
    return
  }

  const loaders = document.querySelectorAll<HTMLInputElement>('.js-diff-progressive-loader')
  if (loaders.length > 0) {
    blankSlate.hidden = true
    return
  }

  const filesOnPage = document.querySelectorAll<HTMLInputElement>('.js-file')
  if (filesOnPage.length === 0) {
    blankSlate.hidden = true
    return
  }

  const isNotEmpty = Array.from(filesOnPage).some(file => !file.hidden)
  blankSlate.hidden = isNotEmpty
}

// Unhides file if hidden by filter and you click to it from jump to menu
on('click', '.js-jump-to-menu-item', function ({currentTarget}) {
  const anchorValue = currentTarget.getAttribute('href')!.substring(1)
  const dataAnchor = document.querySelector(`[data-anchor="${anchorValue}"]`)!
  if (dataAnchor) {
    const file = dataAnchor.closest<HTMLElement>('.js-file.js-details-container')!
    file.hidden = false
  }
  checkEmptyFilterResults()
})
